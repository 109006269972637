<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogGenerateReport"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      scrollable
      max-width="500px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select a report to download</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="dataItem">
          <v-container>
            <div class="d-flex justify-center">
              <v-card
                width="120px"
                height="120px"
                @click.once="generateLink('assessment')"
                class="
                  rounded-max
                  d-flex
                  align-center
                  justify-center
                  flex-column
                  mr-3
                "
              >
                <img
                  src="../../../assets/img/Download Asesment0.png"
                  height="70px"
                  width="70px"
                  alt=""
                />
                Assessment</v-card
              >
              <v-card
                width="120px"
                height="120px"
                @click.once="generateLink('scholar')"
                class="
                  rounded-max
                  d-flex
                  align-center
                  justify-center
                  flex-column
                  mr-3
                "
              >
                <img
                  src="../../../assets/img/Download Scholar0.png"
                  height="70px"
                  width="70px"
                  alt=""
                />
                Scholar</v-card
              >
              <v-card
                width="120px"
                height="120px"
                @click.once="generateLink('all')"
                class="
                  rounded-max
                  d-flex
                  align-center
                  justify-center
                  flex-column
                "
              >
                <img
                  src="../../../assets/img/Download Dua0.png"
                  height="70px"
                  width="70px"
                  alt=""
                />
                All Report</v-card
              >
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dialogDwonloadReport",

  props: ["dialogGenerateReport", "dataItem"],
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  methods: {
    generateLink(report) {
      this.loading = true;
      let data = {
        tipe: "bulk",
        id: this.dataItem,
        report: report,
      };
      this.$store
        .dispatch("report/downloadBatchReport", data)
        .then(() => {
          this.loading = false;
          this.$emit("close");
          this.$router.push(`/generate/report`);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.no_decoration {
  text-decoration: none;
}
</style>
