<template>
  <div class="d-flex align-center flex-column">
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="V.1 0721 Loading Icon 2">
        <g id="Toga">
          <path
            id="Vector"
            d="M79.2528 57.5259L55.3307 65.9413C51.8806 67.1556 48.1194 67.1556 44.6693 65.9413L20.7472 57.5259C20.2496 57.3511 19.8186 57.026 19.5139 56.5955C19.2092 56.1651 19.0458 55.6505 19.0464 55.1231V33.4906C19.0464 32.8155 19.3143 32.1681 19.7912 31.6904C20.2681 31.2128 20.9151 30.9439 21.5901 30.9429H78.4062C79.0818 30.9429 79.7297 31.2113 80.2075 31.6891C80.6852 32.1669 80.9536 32.8149 80.9536 33.4906V55.1231C80.9531 55.6503 80.7893 56.1643 80.4847 56.5946C80.1802 57.0248 79.7498 57.3502 79.2528 57.5259V57.5259Z"
            fill="#024929"
          />
          <path
            id="Vector_2"
            d="M21.5901 30.9429C20.9151 30.9439 20.2681 31.2128 19.7912 31.6904C19.3143 32.1681 19.0464 32.8155 19.0464 33.4906V55.1231C19.0458 55.6505 19.2092 56.1651 19.5139 56.5955C19.8186 57.026 20.2496 57.3511 20.7472 57.5259L44.6693 65.9412C46.3819 66.5414 48.1835 66.8477 49.9982 66.8474V30.9429H21.5901Z"
            fill="#08512E"
          />
          <path
            id="Vector_3"
            d="M98.4816 33.5203L50.6559 48.6686C50.2292 48.8024 49.7717 48.8024 49.345 48.6686L1.51926 33.5203C1.07895 33.3804 0.694572 33.104 0.421788 32.7311C0.149004 32.3582 0.00196838 31.9082 0.00196838 31.4461C0.00196838 30.9841 0.149004 30.5341 0.421788 30.1612C0.694572 29.7883 1.07895 29.5119 1.51926 29.372L49.345 14.2274C49.7717 14.0936 50.2292 14.0936 50.6559 14.2274L98.4816 29.372C98.922 29.5119 99.3063 29.7883 99.5791 30.1612C99.8519 30.5341 99.9989 30.9841 99.9989 31.4461C99.9989 31.9082 99.8519 32.3582 99.5791 32.7311C99.3063 33.104 98.922 33.3804 98.4816 33.5203V33.5203Z"
            fill="#03603A"
          />
          <path
            id="Vector_4"
            d="M49.345 14.2274L1.51926 29.372C1.07895 29.5119 0.694572 29.7883 0.421788 30.1612C0.149004 30.5341 0.00196838 30.9841 0.00196838 31.4461C0.00196838 31.9082 0.149004 32.3582 0.421788 32.7311C0.694572 33.104 1.07895 33.3804 1.51926 33.5203L49.345 48.6686C49.5565 48.7351 49.7769 48.7689 49.9986 48.7689V14.1122C49.7761 14.117 49.5557 14.1559 49.345 14.2274V14.2274Z"
            fill="#056641"
          />
          <path
            id="Vector_5"
            d="M98.6891 29.4574C98.6215 29.4897 98.552 29.5182 98.4812 29.5428L50.6554 44.6875C50.2293 44.825 49.7707 44.825 49.3446 44.6875L1.51882 29.5428C1.44798 29.5182 1.37856 29.4897 1.31086 29.4574C0.905372 29.6337 0.563327 29.9296 0.33042 30.3054C0.0975126 30.6813 -0.0151654 31.1193 0.00743807 31.5609C0.0300415 32.0025 0.18685 32.4267 0.456916 32.7768C0.726982 33.1269 1.09745 33.3863 1.51882 33.5203L49.3446 48.6686C49.7713 48.8024 50.2287 48.8024 50.6554 48.6686L98.4812 33.5203C98.9026 33.3863 99.273 33.1269 99.5431 32.7768C99.8132 32.4267 99.97 32.0025 99.9926 31.5609C100.015 31.1193 99.9025 30.6813 99.6696 30.3054C99.4367 29.9296 99.0946 29.6337 98.6891 29.4574V29.4574Z"
            fill="#024C2A"
          />
          <path
            id="Vector_6"
            d="M49.3446 44.6875L1.51883 29.5428C1.44799 29.5182 1.37857 29.4897 1.31088 29.4574V29.4574C1.053 29.5642 0.819016 29.7213 0.622623 29.9197C0.42623 30.118 0.271376 30.3535 0.167128 30.6124V30.6124C0.13115 30.7073 0.100162 30.804 0.074291 30.9021C0.074291 30.9021 0.074291 30.9021 0.074291 30.9281C0.0540211 31.02 0.0391419 31.113 0.0297294 31.2066C0.0278862 31.2239 0.0278862 31.2413 0.0297294 31.2586C0.0297294 31.3428 0.0297294 31.4282 0.0297294 31.5148C0.0313951 31.5421 0.0313951 31.5693 0.0297294 31.5965C0.0326738 31.6738 0.0413625 31.7508 0.0557237 31.8268L0.0594372 31.9382C0.0728215 32.0048 0.0901821 32.0705 0.111426 32.135C0.111426 32.1796 0.144847 32.2205 0.159701 32.265C0.174555 32.3096 0.204262 32.3764 0.230257 32.4321C0.256251 32.4878 0.282245 32.525 0.311953 32.5695C0.336897 32.6186 0.365445 32.6658 0.397363 32.7107C0.430784 32.7589 0.471632 32.8035 0.51248 32.8518C0.541393 32.8906 0.572384 32.9278 0.605317 32.9632C0.653592 33.0115 0.709294 33.056 0.761283 33.1006L0.86526 33.1897C0.928389 33.2343 0.998945 33.2752 1.0695 33.316L1.16976 33.3754C1.28131 33.4336 1.39821 33.4808 1.51883 33.5166L49.3446 48.6649C49.5561 48.7314 49.7765 48.7652 49.9982 48.7652V44.7915C49.7761 44.7921 49.5555 44.757 49.3446 44.6875V44.6875Z"
            fill="#045633"
          />
          <g id="Group" opacity="0.4">
            <path
              id="Vector_7"
              opacity="0.4"
              d="M68.2758 61.3882L66.7904 61.9155V40.4352L49.1329 32.3504C48.3902 32.0088 48.1897 29.4574 48.7616 28.7258C49.1329 28.2764 49.9053 29.5428 50.1727 29.5688C50.2878 29.5688 51.0676 29.9699 51.2867 30.0739L68.8848 38.129C69.1441 38.2473 69.3638 38.4376 69.5179 38.6772C69.672 38.9169 69.7538 39.1959 69.7538 39.4808V59.7504C69.7612 60.5712 69.0705 61.1059 68.2758 61.3882Z"
              fill="#042B18"
            />
          </g>
          <path
            id="Vector_8"
            d="M69.932 71.0885C71.5358 71.0885 72.836 69.8065 72.836 68.2252C72.836 66.6438 71.5358 65.3619 69.932 65.3619C68.3282 65.3619 67.0281 66.6438 67.0281 68.2252C67.0281 69.8065 68.3282 71.0885 69.932 71.0885Z"
            fill="#B3A369"
          />
          <path
            id="Vector_9"
            d="M73.9909 77.7955H65.8732C65.8732 77.7955 66.3968 69.7516 69.932 69.7516C73.4673 69.7516 73.9909 77.7955 73.9909 77.7955Z"
            fill="#B3A369"
          />
          <path
            id="Vector (Stroke)"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M48.4099 28.7304C48.8114 27.8532 49.848 27.4677 50.7252 27.8692L70.6591 36.9938C71.2804 37.2782 71.6788 37.8988 71.6788 38.5821V70.1935C71.6788 71.1582 70.8968 71.9402 69.9321 71.9402C68.9674 71.9402 68.1853 71.1582 68.1853 70.1935V39.7036L49.2712 31.0457C48.394 30.6441 48.0084 29.6076 48.4099 28.7304Z"
            fill="#B3A369"
          />
        </g>
        <path
          id="Shade"
          opacity="0.2"
          d="M49.9981 85.8767C67.0944 85.8767 80.9536 81.9843 80.9536 77.1828C80.9536 72.3813 67.0944 68.4889 49.9981 68.4889C32.9019 68.4889 19.0427 72.3813 19.0427 77.1828C19.0427 81.9843 32.9019 85.8767 49.9981 85.8767Z"
          fill="black"
        />
      </g>
    </svg>

    <h2>Loading ...</h2>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#Toga {
  animation: toga infinite ease-in-out alternate 0.5s;
  transform-origin: bottom;
  animation-delay: none;
}
#Shade {
  animation: shade infinite ease-in-out alternate 0.5s;
  transform-origin: bottom;
  animation-delay: none;
}

@keyframes toga {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(0, -20%);
  }
}
@keyframes shade {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
</style>