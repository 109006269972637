<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogColumn"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="500px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select The Column To Be Displayed</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="listColumn">
          <v-container>
            <section
              class="px-2 my-3 d-flex justify-space-between"
              v-if="from == 'parent'"
            >
              <v-checkbox
                dense
                hide-details
                @click="selectAll"
                v-model="isSelectAll"
                :value="true"
                color="success"
                label="Select All"
                class="ma-0"
              ></v-checkbox>
              <div v-if="needReset">
                <v-tooltip top color="green" max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      depressed
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="resetCol"
                      color="green"
                      dark
                      ><v-icon small>mdi-backup-restore</v-icon></v-btn
                    >
                  </template>
                  <span>Reset Column</span>
                </v-tooltip>
              </div>
            </section>
            <section
              class="px-2 my-3 d-flex justify-space-between"
              v-if="from == 'child'"
            >
              <v-checkbox
                dense
                hide-details
                @click="selectAll"
                v-model="isSelectAllExp"
                :value="true"
                color="success"
                label="Select All"
                class="ma-0"
              ></v-checkbox>
              <div v-if="needResetExp">
                <v-tooltip top color="green" max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      depressed
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="resetCol"
                      color="green"
                      dark
                      ><v-icon small>mdi-backup-restore</v-icon></v-btn
                    >
                  </template>
                  <span>Reset Column</span>
                </v-tooltip>
              </div>
            </section>
            <v-card
              v-for="(column, idx) in listColumn"
              :key="idx"
              class="pa-2 mb-3"
            >
              <div v-if="from == 'parent'">
                <v-checkbox
                  v-model="selectedColumn"
                  :label="column.text"
                  :value="column"
                  color="success"
                  @click="checkIsSelectAll"
                  dense
                  class="mb-1"
                  hide-details="true"
                ></v-checkbox>
              </div>
              <div v-if="from == 'child'">
                <v-checkbox
                  v-model="exportColumn"
                  :label="column.text"
                  :value="column"
                  color="success"
                  dense
                  class="mb-1"
                  hide-details="true"
                ></v-checkbox>
              </div>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark depressed @click="pickColumn">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dialogColumn",
  computed: {
    ...mapState({
      cache_column: (state) => state.report.cachedColumn,
    }),
  },
  data() {
    return {
      loading: false,
      isSelectAll: null,
      isSelectAllExp: null,

      selectedColumn: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.no_tf",
          cols: "no_tf",
        },
        {
          text: "NO",
          align: "start",
          sortable: false,
          value: "no",
          cols: "no",
        },
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.nama_lengkap",
          cols: "nama_lengkap",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.universitas",
          cols: "universitas",
        },
        {
          text: "MAJOR",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.jurusan",
          cols: "jurusan",
        },
        {
          text: "PHASE",
          sortable: false,
          value: "periode",
          cols: "periode",
        },
        {
          text: "UPDATED BY",
          sortable: false,
          value: "updated_by",
          cols: "updated_by",
        },
        {
          text: "DATE MODIFIED",
          sortable: false,
          value: "updated_at",
          cols: "updated_at",
        },
        {
          text: "VIEW REPORT",
          sortable: false,
          value: "actions",
          cols: "view_report",
        },
      ],
      exportColumn: [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.nama_lengkap",
          cols: "nama_lengkap",
        },
      ],
      needReset: false,
      needResetExp: false,
    };
  },
  props: ["dialogColumn", "listColumn", "from"],
  mounted() {},
  methods: {
    checkCachedColumn() {
      if (this.cache_column) {
        this.selectedColumn = this.cache_column;

        if (this.cache_column.length == 42) {
          this.isSelectAll = true;
          this.needReset = true;
        }
      }
    },
    closePop() {
      this.$emit("close");
      this.resetCol();
    },
    resetCol() {
      this.isSelectAll = null;
      this.needReset = false;

      if (this.from == "parent") {
        this.selectedColumn = [
          {
            text: "ID",
            align: "start",
            sortable: false,
            value: "relationships[0].dataScholar.no_tf",
            cols: "no_tf",
          },
          {
            text: "NO",
            align: "start",
            sortable: false,
            value: "no",
            cols: "no",
          },
          {
            text: "FULL NAME",
            align: "start",
            sortable: false,
            value: "relationships[0].dataScholar.nama_lengkap",
            cols: "nama_lengkap",
          },
          {
            text: "UNIVERSITY",
            align: "start",
            sortable: false,
            value: "relationships[0].dataScholar.universitas",
            cols: "universitas",
          },
          {
            text: "MAJOR",
            align: "start",
            sortable: false,
            value: "relationships[0].dataScholar.jurusan",
            cols: "jurusan",
          },
          {
            text: "PHASE",
            sortable: false,
            value: "periode",
            cols: "periode",
          },
          {
            text: "UPDATED BY",
            sortable: false,
            value: "updated_by",
            cols: "updated_by",
          },
          {
            text: "DATE MODIFIED",
            sortable: false,
            value: "updated_at",
            cols: "updated_at",
          },
          {
            text: "VIEW REPORT",
            sortable: false,
            value: "actions",
            cols: "view_report",
          },
        ];
      } else {
        this.exportColumn = [
          {
            text: "FULL NAME",
            align: "start",
            sortable: false,
            value: "relationships[0].dataScholar.nama_lengkap",
            cols: "nama_lengkap",
          },
        ];
      }
    },
    checkIsSelectAll() {
      if (this.selectedColumn.length == 42) {
        this.needReset = true;
        this.isSelectAll = true;
      } else {
        this.needReset = false;
        this.isSelectAll = false;
      }
    },
    selectAll() {
      let data = this.listColumn.map((x) => {
        return x;
      });
      if (this.from == "parent") {
        if (this.isSelectAll) {
          this.needReset = true;
          this.selectedColumn = data;
        } else {
          this.selectedColumn = [];
        }
      } else {
        if (this.isSelectAllExp) {
          this.needResetExp = true;
          this.exportColumn = data;
        } else {
          this.exportColumn = [];
        }
      }
    },
    pickColumn() {
      let column =
        this.from == "parent" ? this.selectedColumn : this.exportColumn;
      this.$emit("pick", column, this.from);
    },
  },
};
</script>

<style scoped>
.v-dialog {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}
</style>
