<template>
  <v-row justify="start">
    <v-dialog v-model="dialogBulkAct" persistent max-width="400px">
      <v-card class="rounded-max" v-if="dataItem">
        <v-toolbar dense flat>
          <!-- <h2>Move Report To trash</h2> -->
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-container>
          <div class="d-flex flex-column align-center" v-if="dataItem.length">
            <div
              v-if="typeBulk == 'del'"
              class="d-flex flex-column align-center"
            >
              <img
                src="../../../assets/img/delete-icon.png"
                height="150px"
                alt=""
              />
              <div>
                <h2 class="ma-0 text-center">Are you sure?</h2>
                <p class="ma-0 text-center">
                  {{ dataItem.length }} items will be moved to trash
                </p>
              </div>
            </div>
            <div v-if="typeBulk == 'load'">
              <div
                class="d-flex flex-column align-center"
                v-if="dataItem.length <= 10"
              >
                <img
                  src="../../../assets/img/Files Download0.png"
                  height="150px"
                  alt=""
                />
                <p class="ma-0 text-center">
                  {{ dataItem.length }} items will be downloaded
                </p>
              </div>
              <div
                class="d-flex flex-column align-center"
                v-if="dataItem.length > 10"
              >
                <img
                  src="../../../assets/img/notFound.png"
                  height="150px"
                  alt=""
                />
                <p class="ma-0 text-center">
                  Maximum download is <b>10 data</b>
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="!dataItem.length"
            class="mb-3 d-flex justify-center flex-column align-center"
          >
            <img
              src="../../../assets/img/V.1 250821 Icon Select Item Deleted svg0.svg"
              height="200px"
              alt=""
            />
            <h3 class="text-center mt-3">Please select the item first</h3>
          </div>
        </v-container>
        <v-card-actions v-if="dataItem.length">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="text-capitalize"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <div v-if="typeBulk == 'del'">
            <v-btn
              v-if="!loading"
              color="red"
              class="text-capitalize"
              depressed
              dark
              @click="deleteItem"
              ><v-icon class="mr-2">mdi-delete</v-icon>
              <p class="ma-0">move to trash</p></v-btn
            >
            <v-btn
              v-if="loading"
              color="red"
              class="text-capitalize"
              dark
              depressed
              ><v-progress-circular
                :size="25"
                class="mr-2"
                indeterminate
              ></v-progress-circular>
              Loading</v-btn
            >
          </div>
          <div v-if="typeBulk == 'load'">
            <div v-if="dataItem.length <= 10">
              <v-btn
                v-if="!loading"
                color="green"
                class="text-capitalize"
                depressed
                dark
                @click="generateLink"
                ><v-icon class="mr-2">mdi-folder-download</v-icon>
                <p class="ma-0">Download</p></v-btn
              >
              <v-btn
                v-if="loading"
                color="green"
                class="text-capitalize"
                dark
                depressed
                ><v-progress-circular
                  :size="25"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular>
                Generating</v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "bulkActionReport",
  data() {
    return {
      loading: false,
    };
  },
  props: ["dialogBulkAct", "dataItem", "role", "typeBulk"],

  methods: {
    generateLink() {
     this.$emit('selectType')
    },
    deleteItem() {
      this.loading = true;
      let body = {
        id: this.dataItem,
      };
      let data = {
        body: body,
        type: "report",
      };
      this.$store
        .dispatch("trash/moveToTrash", data)
        .then((data) => {
          if (data.status == "success") {
            this.$emit("after");
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
