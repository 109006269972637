<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogExport"
      @click:outside="$emit('close')"
      scrollable
      max-width="559px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select date to export data</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <div>
              <v-row>
                <v-col cols="3"> Column </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-wrap">
                    <p class="ma-0" v-for="(col, i) in column" :key="i">
                      {{ col.text }}, &nbsp;
                    </p>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-tooltip top color="info" max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        depressed
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        dark
                        class="mr-1"
                        ><v-icon small>mdi-help</v-icon></v-btn
                      >
                    </template>
                    <span>List of columns to be exported</span>
                  </v-tooltip>
                  <v-btn
                    fab
                    depressed
                    x-small
                    color="green"
                    dark
                    @click="$emit('showColumn')"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="3"> Tahun TF </v-col>
                <v-col cols="6">
                  <!-- {{ year ? year : "All Years" }} -->
                  <v-select
                    dense
                    hide-details
                    :items="getYear"
                    item-text="state"
                    item-value="abbr"
                    v-model="setYear"
                    label="Filter Year"
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-tooltip top color="info" max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        depressed
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        dark
                        ><v-icon small>mdi-help</v-icon></v-btn
                      >
                    </template>
                    <span>Filter Tahun</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="3"> Phase </v-col>
                <v-col cols="6">
                  <!-- {{ status ? status : "All Scholars" }} -->
                  <v-select
                    dense
                    hide-details
                    :items="items"
                    item-text="state"
                    item-value="abbr"
                    v-model="setPhase"
                    label="Filter"
                    @change="changeDrop(phase, 'phase')"
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-tooltip top color="info" max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        depressed
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        dark
                        ><v-icon small>mdi-help</v-icon></v-btn
                      >
                    </template>
                    <span>Filter Phase</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="d-flex justify-center mt-5">
                <template>
                  <v-btn
                    @click="mutateColumn"
                    color="green"
                    dark
                    v-if="!loading"
                    ><v-icon>mdi-file-export</v-icon> Export Data</v-btn
                  >
                  <v-btn color="green" dark v-if="loading">
                    Generating...</v-btn
                  >
                </template>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dialogDwonloadReport",

  props: ["dialogExport", "column"],

  data() {
    return {
      calendar1: false,
      dateRangeTxt: null,
      range: null,
      loading: false,
      dateTime: "",
      terambil: null,
      items: [
        { state: "BASELINE", abbr: "baseline" },
        { state: "MIDLINE", abbr: "midline" },
        { state: "ENDLINE", abbr: "endline" },
      ],
    };
  },

  computed: {
    ...mapState({
      years: (state) => state.report.yearFilter,
      phase: (state) => state.report.phaseFilter,
    }),
    setPhase: {
      get() {
        return this.phase;
      },
      set() {
        return this.phase;
      },
    },
    setYear: {
      get() {
        return this.years;
      },
      set() {
        return this.years;
      },
    },
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push({
          state: i,
          abbr: i,
        });
      }
      data.push({
        state: "All Year",
        abbr: null,
      });
      return data;
    },
  },

  methods: {
    changeDrop(val, type) {
      if (type == "phase") {
        this.$store.commit("report/MUTATE_PHASE", val);
      }
    },

    mutateColumn() {
      let columns = [];
      this.column.forEach((i) => {
        if (
          i.text !== "ID" &&
          i.text !== "PHASE" &&
          i.text !== "UPDATED BY" &&
          i.text !== "VIEW REPORT" &&
          i.text !== "DATE MODIFIED"
        )
          columns.push(i.cols);
      });
      this.exportToExcel(columns);
    },
    exportToExcel(col) {
      this.loading = true;
      let data = {
        columns: col,
        tahun_tf: this.setYear,
        periode: this.setPhase,
      };
      this.$store.dispatch("exports/exportReport", data).then((data) => {
        this.loading = false;
        location.href = data.data.path;
        this.$emit("close");
        this.$emit('reset')
      });
    },
  },
};
</script>
<style scoped>
.no_decoration {
  text-decoration: none;
}
</style>
