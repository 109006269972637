<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogSearchUser"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="500px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Create Scholar's Report</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <div v-if="!loading">
              <v-text-field
                solo
                type="search"
                autocomplete="off"
                label="Type name or Scholar's email"
                rounded
                append-icon="mdi-magnify"
                hide-details
                flat
                v-model="find"
                background-color="#ECEFF1"
                @keyup="search"
              ></v-text-field>
              <section v-if="user" class="mt-5">
                <v-card
                  class="mb-2 pa-3 rounded-lg d-flex"
                  elevation="1"
                  v-for="item in user.data"
                  :key="item.id"
                  @click="
                    $emit(
                      'selectPeriode',
                      item.relationships[0].scholar.id,
                      item.relationships[0].scholar.jenis_kelamin
                    )
                  "
                >
                  <div class="mr-3">
                    <v-btn depressed fab small color="blue lighten-5"
                      ><v-icon color="blue">mdi-account</v-icon></v-btn
                    >
                  </div>
                  <div>
                    <h3 class="mb-n2">{{ item.nama_lengkap }}</h3>
                    <small class="grey--text">{{ item.email }}</small>
                  </div>
                </v-card>
                <div
                  v-if="!user.data.length"
                  class="pa-2 d-flex flex-column align-center justify-center"
                >
                  <img
                    src="../../../../assets/img/notFound.png"
                    height="130px"
                    alt=""
                  />
                  <strong class="mt-2" style="font-size: large">No Data</strong>
                </div>
              </section>
            </div>
            <div class="pa-2" v-if="loading">
              <loadingMD />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import loadingMD from "../../../etc/loading-component/loadingMD.vue";
export default {
  components: { loadingMD },
  name: "SearchUser",
  computed: {
    ...mapState({
      user: (state) => state.user.list_user,
    }),
  },
  data() {
    return {
      loading: false,
      find: "",
    };
  },
  props: ["dialogSearchUser"],
  mounted() {
    this.search();
  },
  methods: {
    search(filter) {
      this.loading = true;
      let data = {
        role: "scholar",
        include: "scholar",
        find: this.find,
        limit: 200,
      };
      data = { ...data, ...filter };
      this.$store.dispatch("user/listUser", data).then(() => {
        this.loading = false;
      });
    },

  },
};
</script>

<style scoped>
.v-dialog {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}
</style>
