<template>
  <v-row justify="start">
    <!-- UNTUK FILTER DOWNLOAD BATCH -->
    <v-dialog
      v-model="dialogFilterDownload"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="530px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <v-select
              dense
              :items="getYear"
              item-text="state"
              item-value="abbr"
              v-model="year"
              label="Filter Year"
              solo
            ></v-select>
            <v-select
              dense
              :items="items"
              item-text="state"
              item-value="abbr"
              v-model="periode"
              label="Filter"
              solo
            ></v-select>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!loading"
            class="text-capitalize"
            color="green"
            dark
            @click="generateLink()"
          >
            <v-icon>mdi-folder-download</v-icon> Generate Link Download</v-btn
          >
          <v-btn v-if="loading" class="text-capitalize" color="green" dark>
            generating...</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogPeriodeView",

  props: ["dialogFilterDownload"],
  computed: {
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push({
          state: i,
          abbr: i,
        });
      }
      data.push({
        state: "All Year",
        abbr: "",
      });
      return data;
    },
  },
  data() {
    return {
      items: [
        { state: "BASELINE", abbr: "baseline" },
        { state: "MIDLINE", abbr: "midline" },
        { state: "ENDLINE", abbr: "endline" },
        { state: "All Phase", abbr: "" },
      ],
      year: "",
      periode: "",
      loading: false,
    };
  },

  methods: {
    generateLink() {
      this.loading = true;
      let data = {
        tipe: "batch",
        periode: this.periode,
        tahun_tf: this.year,
      };
      this.$store
        .dispatch("report/downloadBatchReport", data)
        .then(() => {
          this.loading = false;
          this.$emit("close");
          this.$router.push(`/generate/report`);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>
