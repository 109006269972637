<template>
  <div class="pa-3">
    <v-toolbar dense flat class="mb-5" color="#EDF1F0">
      <h2 class="text-capitalize">List Report</h2>
      <v-spacer></v-spacer>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN'"
            class="text-capitalize mr-2"
            color="green"
            dark
            v-bind="attrs"
            v-on="on"
            @click="dialogFilterDownload = true"
          >
            <v-icon>mdi-folder-download</v-icon></v-btn
          >
        </template>
        <span>Download Report Perbatch</span>
      </v-tooltip> -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN'"
            class="text-capitalize mr-2"
            color="green"
            dark
            v-bind="attrs"
            v-on="on"
            @click="manualInput"
          >
            <v-icon>mdi-plus-box</v-icon></v-btn
          >
        </template>
        <span>Manual Input</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN'"
            class="text-capitalize"
            color="green"
            dark
            v-bind="attrs"
            v-on="on"
            @click="selectType('multiple', 'bi')"
          >
            <v-icon>mdi-plus-box-multiple</v-icon></v-btn
          >
        </template>
        <span>Automatic Input</span>
      </v-tooltip>
    </v-toolbar>

    <v-card class="rounded-max" style="overflow: hidden" flat>
      <div class="pa-3">
        <v-card flat color="#EDF1F0" class="rounded-lg pa-3">
          <v-row v-if="user">
            <v-col
              cols="12"
              :md="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN' ? 3 : 4"
            >
              <v-text-field
                solo
                dense
                type="search"
                autocomplete="off"
                label="Search "
                prepend-inner-icon="mdi-magnify"
                hide-details
                flat
                v-model="find"
                @keydown="getItem(true)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2" v-if="!bulkDel">
              <v-select
                dense
                hide-details
                :items="items"
                item-text="state"
                item-value="abbr"
                v-model="periode"
                @change="getItem()"
                label="Filter"
                flat
                solo
              ></v-select>
            </v-col>
            <v-col cols="6" md="2">
              <v-select
                v-if="!bulkDel"
                dense
                hide-details
                :items="getYear"
                item-text="state"
                item-value="abbr"
                v-model="filterYear"
                @change="getItem()"
                label="Filter Year"
                flat
                solo
              ></v-select>
            </v-col>
            <v-col cols="6" md="2">
              <v-btn
                v-if="!bulkDel"
                block
                color="white"
                depressed
                class="text-capitalize"
                height="100%"
                @click="selectColumns('parent')"
                ><v-icon color="orange" class="mr-1">mdi-filter-variant</v-icon>
                Filter Column</v-btn
              >
            </v-col>
            <v-col cols="6" md="2" v-if="!bulkDel">
              <v-btn
                block
                color="white"
                depressed
                class="text-capitalize"
                height="100%"
                @click="dialogExport = true"
                ><v-icon color="green" class="mr-1">mdi-microsoft-excel</v-icon>
                Export Data</v-btn
              >
            </v-col>
            <v-col cols="6" md="2" v-if="bulkDel">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="green"
                    depressed
                    class="text-capitalize"
                    height="100%"
                    @click="bulkAct('load')"
                    ><v-icon class="mr-1">mdi-progress-download</v-icon>
                    Download</v-btn
                  >
                </template>
                <span>Download reports in bulk</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6" md="2" v-if="bulkDel">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    depressed
                    class="text-capitalize"
                    height="100%"
                    @click="bulkAct('del')"
                    ><v-icon class="mr-1">mdi-delete</v-icon> Delete</v-btn
                  >
                </template>
                <span>Delete reports in bulk</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="6"
              md="1"
              v-if="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN'"
            >
              <v-tooltip top v-if="!bulkDel">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    depressed
                    class="text-capitalize"
                    @click="bulkDel = true"
                    ><v-icon color="green"
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Bulk Action</span>
              </v-tooltip>
              <v-btn
                v-if="bulkDel"
                color="white"
                depressed
                class="text-capitalize"
                @click="bulkDel = false"
                ><v-icon color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-skeleton-loader type="table-tbody" v-if="loading"></v-skeleton-loader>
      <div class="mt-5 familiy" v-if="!loading && user">
        <v-data-table
          :headers="headers"
          :items="user.data"
          :page.sync="page"
          :items-per-page="perPage"
          class="elevation-0"
          :show-select="bulkDel"
          :single-select="false"
          v-model="tempId"
          hide-default-footer
        >
          <template v-slot:[`item.no`]="{ item }">
            {{
              user.data
                .map((x) => {
                  return x.id;
                })
                .indexOf(item.id) +
              1 +
              (page - 1) * perPage
            }}
          </template>
          <template v-slot:[`item.periode`]="{ item }">
            <div class="d-flex">
              <v-btn rounded color="green lighten-5" depressed>
                <p class="ma-0 green--text text-capitalize">
                  {{ item.periode }}
                </p>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <div class="d-flex">
              {{ $date(item.updated_at).format("DD MMMM YYYY") }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    depressed
                    color="green"
                    dark
                    :id="item.actions"
                    v-bind="attrs"
                    v-on="on"
                    :to="`/report/${item.periode}/${item.scholar_id}`"
                  >
                    <v-icon dark small> mdi-text-box-check </v-icon>
                  </v-btn>
                </template>
                <span>View Report</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <div class="text-center pa-3" v-if="user">
      <v-pagination
        v-model="page"
        color="green"
        @input="getItem"
        :length="user.meta.last_page"
      ></v-pagination>
    </div>
    <div class="pa-3">
      <SelectPeriodeView
        v-bind:dialogPeriodeView="dialogPeriodeView"
        @close="closeDialog(1)"
        v-bind:idScholar="idScholar"
      />
      <SelectPeriodeBatch
        v-bind:dialogPeriodeBatch="dialogPeriodeBatch"
        v-bind:from="from"
        v-bind:gender="gender"
        @close="closeDialog(2)"
      />
      <GetListUser
        v-bind:dialogSearchUser="dialogSearchUser"
        @selectPeriode="selectType"
        @close="closeDialog(3)"
        ref="manualInput"
      />
      <ExportData
        v-bind:dialogExport="dialogExport"
        v-bind:column="headersExport"
        @showColumn="selectColumns('child')"
        @close="closeDialog(4)"
        @reset="resetColumn()"
      />
      <SelectColumn
        v-bind:dialogColumn="dialogColumn"
        v-bind:listColumn="displayedColumn"
        v-bind:from="columnFrom"
        @pick="changeColumn"
        @close="closeDialog(5)"
        ref="column"
      />
      <BulkActionReport
        v-bind:dialogBulkAct="dialogBulkAct"
        v-bind:typeBulk="typeBulk"
        v-bind:dataItem="placeId"
        @after="afterDelete"
        @close="closeDialog(6)"
        @selectType="selctTypeToGenerate"
      />
      <FilterPhaseAndYear
        v-bind:dialogFilterDownload="dialogFilterDownload"
        @close="closeDialog(7)"
      />
      <SelectTypeGenerateReport
        v-bind:dialogGenerateReport="dialogGenerateReport"
        v-bind:dataItem="placeId"
        @close="closeDialog(8)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GetListUser from "../../../components/Report/Create/Modal/getListUser.vue";
import SelectColumn from "../../../components/Report/Create/Modal/selectColumn.vue";
import SelectPeriodeBatch from "../../../components/Report/Create/Modal/selectPeriode.vue";
import ExportData from "../../../components/Report/Download/exportReport.vue";
import SelectTypeGenerateReport from "../../../components/Report/Download/selectTypeGenerateReport.vue";
import FilterPhaseAndYear from "../../../components/Report/view/Modal/filterPhaseAndYear.vue";
import SelectPeriodeView from "../../../components/Report/view/Modal/selectPeriode.vue";
import BulkActionReport from "../Delete/bulkActionReport.vue";

export default {
  name: "ListReport",
  components: {
    GetListUser,
    SelectPeriodeBatch,
    SelectPeriodeView,
    ExportData,
    SelectColumn,
    BulkActionReport,
    FilterPhaseAndYear,
    SelectTypeGenerateReport,
  },
  computed: {
    ...mapState({
      user: (state) => state.report.list_report,
      role: (state) => state.role,
      cache_column: (state) => state.report.cachedColumn,
    }),
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push({
          state: i,
          abbr: i,
        });
      }
      data.push({
        state: "All Year",
        abbr: "",
      });
      return data;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.route = this.$route.params.role;
      this.getItem();
    },
  },
  data() {
    return {
      find: "",
      periode: "",
      route: null,
      dataItem: null,
      loading: false,
      dialogPeriodeBatch: false,
      dialogPeriodeView: false,
      dialogSearchUser: false,
      dialogBulkAct: false,
      typeBulk: "",
      dialogColumn: false,
      dialogExport: false,
      idScholar: null,
      from: "",
      listColumn: null,
      items: [
        { state: "BASELINE", abbr: "baseline" },
        { state: "MIDLINE", abbr: "midline" },
        { state: "ENDLINE", abbr: "endline" },
        { state: "ALL PHASE", abbr: "" },
      ],
      headersExport: [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.nama_lengkap",
          cols: "nama_lengkap",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.no_tf",
          cols: "no_tf",
        },
        {
          text: "NO",
          align: "start",
          sortable: false,
          value: "no",
          cols: "no",
        },
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.nama_lengkap",
          cols: "nama_lengkap",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.universitas",
          cols: "universitas",
        },
        {
          text: "MAJOR",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.jurusan",
          cols: "jurusan",
        },
        {
          text: "PHASE",
          sortable: false,
          value: "periode",
          cols: "periode",
        },
        {
          text: "UPDATED BY",
          sortable: false,
          value: "updated_by",
          cols: "updated_by",
        },
        {
          text: "DATE MODIFIED",
          sortable: false,
          value: "updated_at",
          cols: "updated_at",
        },
        {
          text: "VIEW REPORT",
          sortable: false,
          value: "actions",
          cols: "view_report",
        },
      ],
      filterYear: null,
      page: 1,
      perPage: 10,
      gender: "",
      columnFrom: "parent",
      columnExport: [],
      displayedColumn: [],
      bulkDel: false,
      tempId: [],
      placeId: null,
      dialogFilterDownload: false,
      dialogGenerateReport: false,
    };
  },
  mounted() {
    this.route = this.$route.params.role;
    this.getItem();
    this.createColumn();
    this.checkCachedColumn();
  },
  methods: {
    // Request teh dhia, column jangan kereset kalo pindah page
    checkCachedColumn() { 
      if (this.cache_column) {
        this.headers = this.cache_column;
      }
    },
    // dialog bulk action
    bulkAct(type) {
      let ids = this.tempId.map((el) => {
        return el.id;
      });
      this.typeBulk = type;
      this.placeId = ids;
      this.dialogBulkAct = true;
    },
    // select tipe report untuk generate as zip
    selctTypeToGenerate() {
      this.closeDialog(6);
      this.dialogGenerateReport = true;
    },
    // setelah bulk delete akan melakukan :
    afterDelete() {
      this.getItem();
      this.closeDialog(6);
      this.bulkDel = false;
    },

    /*
        SELECT COLUMN
        type parent & child
        kalo parent berarti untuk display di web
        kalo child berarti untuk di export excel
    */
    selectColumns(from) {
      this.columnFrom = from;
      this.dialogColumn = true;
      this.displayedColumn =
        from == "parent" ? this.listColumn : this.columnExport;
      if (from == "parent") {
        this.$refs.column.checkCachedColumn();
      }
    },
    /*
       select type untuk apa ya ini lupa
    */
    selectType(type, gender) {
      if (gender == "Laki-laki") {
        this.gender = "l";
      } else if (gender == "Perempuan") {
        this.gender = "p";
      } else {
        this.gender = "gay";
      }
      this.from = type;
      this.dialogPeriodeBatch = true;
    },
    
    // get list get item 
    getItem(isSearching) {
      this.loading = true;
      let data = {
        find: this.find,
        periode: this.periode,
        year: this.filterYear,
        page: isSearching == true ? "" : this.page,
        limit: this.perPage,
      };
      this.$store.commit("report/MUTATE_YEAR", this.filterYear);
      this.$store.commit("report/MUTATE_PHASE", this.periode);  
      this.$store.dispatch("report/listReport", data).then(() => {
        this.loading = false;
      });
    },

    manualInput() {
      this.dialogSearchUser = true;
      let filter = {
        periode: this.periode,
        year: this.filterYear,
      };
      this.$refs.manualInput.search(filter);
    },

    createColumn() {
      let optionheader = [...this.headers];
      this.$store.dispatch("getColumn", "report-summary").then((data) => {
        data.forEach((k) => {
          if (
            k.request !== "no_tf" &&
            k.request !== "nama_lengkap" &&
            k.request !== "universitas" &&
            k.request !== "jurusan" &&
            k.request !== "periode" &&
            k.request !== "updated_by" &&
            k.request !== "updated_at"
          ) {
            optionheader.push({
              text: k.view,
              sortable: false,
              value: k.request,
              cols: k.request,
            });
          }
        });
        // SELEKSI KOLOM YANG GA ADA DI EXCEL
        let exceptColumn = [
          "no_tf",
          "no",
          "universitas",
          "jurusan",
          "periode",
          "updated_by",
          "updated_at",
          "view_report",
        ];
        this.columnExport = optionheader.filter(
          ({ cols: a }) => !exceptColumn.some((b) => a === b)
        ); //FUNGSI BUAT NYARI ITEM YANG SAMA TAPI AMBIL ITEM YANG BEDA DENGAN COMPARE 2 ARRAY

        // SELEKSI KOLOM YANG GA ADA DI WEB
        let tempArr = [];
        optionheader.forEach((l) => {
          if (
            l.cols !== "competency_desc" &&
            l.cols !== "competency_value" &&
            l.cols !== "psychological_desc" &&
            l.cols !== "deskripsi"
          ) {
            tempArr.push(l);
          }
        });
        this.listColumn = tempArr;
      });
    },

    changeColumn(column, from) {
      let picked = [];
      if (from == "parent") {
        this.listColumn.forEach((act) => {
          column.forEach((mutate) => {
            if (act.value == mutate.value) {
              picked.push(act);
            }
          });
        });
        this.headers = picked;
        this.$store.commit("report/CACHE_COLUMN", picked);
      } else {
        this.columnExport.forEach((act) => {
          column.forEach((mutate) => {
            if (act.value == mutate.value) {
              picked.push(act);
            }
          });
        });
        this.headersExport = picked;
      }
      this.closeDialog(5);
    },
    
    resetColumn() {
      this.headersExport = [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "relationships[0].dataScholar.nama_lengkap",
          cols: "nama_lengkap",
        },
      ];
      this.columnFrom = "child";
      this.$refs.column.resetCol();
    },

    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogPeriodeView = false;
          break;
        case 2:
          this.dialogPeriodeBatch = false;
          break;
        case 3:
          this.dialogSearchUser = false;
          break;
        case 4:
          this.dialogExport = false;
          break;
        case 5:
          this.dialogColumn = false;
          break;
        case 6:
          this.dialogBulkAct = false;
          break;
        case 7:
          this.dialogFilterDownload = false;
          break;
        case 8:
          this.dialogGenerateReport = false;
          break;
      }
    },
  },
};
</script>

<style>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.familiy {
  font-family: "Roboto", sans-serif !important;
}
</style>
