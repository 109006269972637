<template>
  <v-row justify="start">
    <v-dialog v-model="dialogPeriodeBatch" @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
     scrollable max-width="530px">
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select a report to create </h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="gender">
          <v-container>
            <div class="d-flex justify-space-between">
              <v-card
                width="150px"
                height="150px"
                
                @click="toCreatePage('baseline')"
                class="d-flex align-center justify-center flex-column pa-3"
              >
                <img
                  :src="require(`../../../../assets/img/ava-baseline-${gender}.png`)"
                  height="90px"
                  class="mb-3"
                  alt="ava"
                />
                Baseline</v-card
              >
              <v-card
                width="150px"
                height="150px"
                @click="toCreatePage('midline')"
                class="d-flex align-center justify-center flex-column pa-3"
              >
                <img
                  :src="require(`../../../../assets/img/ava-midline-${gender}.png`)"
                  height="90px"
                  class="mb-3"
                  alt="ava"
                />

                Midline</v-card
              >
              <v-card
                width="150px"
                height="150px"
                @click="toCreatePage('endline')"
                class="d-flex align-center justify-center flex-column pa-3"
              >
                <img
                  :src="require(`../../../../assets/img/ava-endline-${gender}.png`)"
                  height="90px"
                  class="mb-3"
                  alt="ava"
                />
                Endline</v-card
              >
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogPeriodeBatch",

  props: ["dialogPeriodeBatch", "from", "gender"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
 
    toCreatePage(type) {
      if (this.from == "multiple") {
        this.$router.push(`/batch/report/${type}`);
      } else {
        this.checkIsReportExist(type);
      }
    },
    checkIsReportExist(type) {
      this.loading = true;
      let data = {
        id: this.from,
        periode: type,
      };
      this.$store.dispatch("report/viewScore", data).then((data) => {
        this.loading = false;
        if (data.code == 404) {
          this.$router.push(`/create/report/${this.from}/${type}`);
        } else {
          this.$router.push(`/report/${type}/${this.from}`);
        }
      });
    },
  },
};
</script>
